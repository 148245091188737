import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F4F1EF',
      neutralLight2: '#E2DDDA',
      neutralDark4: '#4C6A80',
      neutralDark3: '#023355',
      neutralDark2: '#004371',
      neutralDark1: '#000000',
      primaryDark: '#DDAD25',
      primaryLight: '#EDB923',
      secondaryLight: '#6FB1E6',
      fadeDark: '#0A1E46',
      dangerLight: '#EE2E31',
    },
  },
  fontFamily: {
    heading: "'El Messiri', sans-serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
